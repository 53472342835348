import React, { useRef, useState } from "react"
import Slider from "react-slick"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowLeft } from "@fortawesome/pro-light-svg-icons/faLongArrowLeft"
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons/faLongArrowRight"

import { Image } from "../Core"
import { Container } from "../Containers"

const ImageSlider = ({ images = [] }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const sliderRef = useRef(null)

  const settings = {
    className: "slider variable-width",
    dots: false,
    autoplay: false,
    speed: 1000,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    afterChange: current => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerMode: false,
          dots: true,
          adaptiveHeight: true,
          variableWidth: false
        }
      }
    ]
  }
  return (
    <div>
      <Slider ref={sliderRef} {...settings}>
        {images.map(img => (
          <Image crop="fit" height="500" key={img} publicId={img} />
        ))}
      </Slider>
      <Container medium className="slick-custom-arrows mt-3">
        <FontAwesomeIcon
          icon={faLongArrowLeft}
          className="slick-custom-arrow"
          onClick={() => sliderRef.current.slickPrev()}
        />
        <FontAwesomeIcon
          icon={faLongArrowRight}
          className="slick-custom-arrow"
          onClick={() => sliderRef.current.slickNext()}
        />
      </Container>
    </div>
  )
}

export default ImageSlider
