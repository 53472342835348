import React from "react"
import { graphql, Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons/faArrowRightLong"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import BodySections from "../components/BodySections/BodySections"
import { Section, Container } from "../components/Containers"
import { Text, Image } from "../components/Core"
import { AnchorLinksBar } from "../components/AnchorLinksBar"

const HistoryPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { title, hero, anchorLink, anchors, heading, sections, cta } =
    data.allUniquePagesJson.nodes[0]

  const rootUrl = title.substring(0, title.length - 1)

  return (
    <Layout className="history-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <Image wrapperClassName="gradient-curtain" publicId={hero.image} useAR />

      <AnchorLinksBar rootUrl={rootUrl} links={anchors} />

      <Section>
        <Container>
          <Text
            id={anchorLink.id}
            className="has-text-centered"
            as="h1"
            text={heading}
          />
        </Container>
      </Section>

      <BodySections sections={sections} />

      <Section colorBack>
        <Container small>
          <Text as="h4" text={cta.heading} />
          <Text as="p" text={cta.blurb} />

          <div className="button-group mt-4">
            {cta.buttons.map(({ button }) => (
              <Link key={button.href} className="arrow-link" to={button.href}>
                {button.buttonText}
                <FontAwesomeIcon icon={faArrowRightLong} />
              </Link>
            ))}
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export const historyQuery = graphql`
  query historyquery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        hero {
          image
        }
        anchorLink {
          text
          id
        }
        anchors {
          text
          id
        }
        heading
        sections {
          type
          text
          images
          colorBack
          element
          id
          imageText {
            reverse
            text
            image
          }
          container
        }
        cta {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
      }
    }
  }
`

export default HistoryPage
